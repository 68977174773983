import requestAPI from "../../../util/baseUrlCustomer";
import scannerApi from "../../../util/printerUrl";

export function getData(param) {
  return requestAPI({
    url: "/UserManagmentGeteway/Printer/GetData",
    method: "Get",
    params: param,
  });
}
export function getDetails(param) {
  return requestAPI({
    url: "/UserManagmentGeteway/Printer/GetDetails",
    method: "Get",
    params: param,
  });
}
export function insertData(data) {
  return requestAPI({
    url: "/UserManagmentGeteway/Printer/insertData",
    method: "Post",
    data,
  });
}
export function reprintCheque(data) {
  return requestAPI({
    url: "/UserManagmentGeteway/Printer/rePrint",
    method: "Post",
    data,
  });
}

// export function updateData(data) {
//   return requestAPI({
//     url: "/UserManagmentGeteway/Printer/updateData",
//     method: "Put",
//     data,
//   });
// }

export function scannMicr(param) {
  return scannerApi({
    url: "/api/Printer/scannMicr",
    method: "Get",
    params: param,
  });
}

export function postPrint(data) {
  return requestAPI({
    url: "/api/Printer/PostPrint",
    method: "Post",
    data,
  });
}


export function openPrinter(data) {
  return requestAPI({
    url: "/api/Printer/OpenPrinter",
    method: "Post",
    data,
  });
}
import {
  getData,
  insertData,
  // updateData,
  getDetails,
  reprintCheque,
  scannMicr,
  postPrint,
  openPrinter
} from "../apis/index.js";

import { createHelpers } from "vuex-map-fields";
const { getDataFields } = createHelpers({
  getterType: "getDataFields",
});
export default {
  namespaced: true,
  state: {
    grids: [],
    details: null,
    scannedMicr: null,
    pageCount: 0,
  },

  getters: {
    getDataFields,
    GET_DETAILS(state) {
      return state.details;
    },
  },

  mutations: {

    SET_DATA_GRID(state, data) {
      state.grids = data;
    },
    SET_DATA_DETAILS(state, data) {
      state.details = data;
    },

    SET_PAGE_COUNT(state, pageCount) {
      state.pageCount = pageCount;
    },

    ///
    ///
    SET_DATA_MICCR(state, data) {
      state.scannedMicr = data;
    },
  },

  actions: {


    GetData({ commit }, filter) {
      return new Promise((resolve, reject) => {
        getData(filter)
          .then((response) => {
            commit("SET_DATA_GRID", response.result.data);
            commit("SET_PAGE_COUNT", response.result.pageCount);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    GetDetails({ commit }, filter) {
      return new Promise((resolve, reject) => {
        getDetails(filter)
          .then((response) => {
            commit("SET_DATA_DETAILS", response.result);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    InsertData({ commit }, item) {
      return new Promise((resolve, reject) => {
        insertData(item)
          .then((response) => {
            console.log(commit);
            resolve(response.messages[0]);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    ReprintCheque({ commit }, item) {
      return new Promise((resolve, reject) => {
        reprintCheque(item)
          .then((response) => {
            console.log(commit);
            resolve(response.messages[0]);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },



    ////
    ////
    ////


    
    ScannMicr({ commit }, filter) {
      return new Promise((resolve, reject) => {
        scannMicr(filter)
          .then((response) => {
            commit("SET_DATA_MICCR", response.scannedMicr);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    PostPrint({ commit }, item) {
      return new Promise((resolve, reject) => {
        postPrint(item)
          .then((response) => {
            console.log(commit);
            resolve(response.messages[0]);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    
    OpenPrinter({ commit }, item) {
      return new Promise((resolve, reject) => {
        openPrinter(item)
          .then((response) => {
            console.log(commit);
            resolve(response.messages[0]);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // UpdateData({ commit }, item) {
    //   return new Promise((resolve, reject) => {
    //     updateData(item)
    //       .then((response) => {
    //         console.log(commit);
    //         resolve(response.messages[0]);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },

  },
};
